import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { PriceCard } from './PriceCard';
import FixedIcon from '../../../shared/images/fixed.svg';
import HourIcon from '../../../shared/images/byhour.svg';

export const Price = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='mainContainer price_container' id='price'>
      <h1 >{t('price')}</h1>
      <p className='containerSubTitle'>{t('weWorkToTypes')}</p>
      <div className='price_innerContainer'>
        <PriceCard
          title={t('fixPrice')}
          text={t('aboutFix')}
          icon={FixedIcon}
        />
        <PriceCard
          title={t('timePrice')}
          text={t('aboutTimePrice')}
          icon={HourIcon}
        />
      </div>
      <div className='price_moreInfo_button'>
        <button className='colorfull-button' onClick={() => { navigate('/price/priceFixOption'); window.scrollTo(0, 0) }}>{t('learnMore')}</button>
      </div>
    </div>
  );
};
