import './index.css';
import Error from '../../shared/images/404.svg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export const Page404 = () => {
  const { t } = useTranslation();

  return (
    <div className="page-404">
        <Helmet>
          <title>LkeyStudio | Error 404</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      <img src={Error} alt="404"  />
      <p className="error-text">{t('errorText')}</p>
      <button className="colorfull-button" onClick={() => window.history.back()}>
        {t('toMain')}
      </button>
    </div>
  );
};
